.infographicMobile {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.infoVideo {
    width: 80% !important;
    max-width: 1000px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-top: 1rem;
}

.tryButtons {
    padding: 3rem;
    border-radius: 50%;
    border: 2px inset #cce9da;
}

.arrowLinkMobile {
    margin-top: 1rem;
    display: flex;
}

.mainPage {
    margin-top: 6rem;
}
.mainGrid {
    margin-top: 3rem;
    width: 100%;
}

h3.mainStatement {
    font-weight: bold;
    margin-top: 3rem;
    margin-bottom: 1rem;
}

h4.mainPoint {
    margin-top: 1rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
}

h5.subHeader {
    margin-bottom: 1rem;
    color: #3f6b72;
}
.mainContainer {
}

.focusContainer {
}

.mobileAgilityText {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.mobileFocusText {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.mobileProductivityText {
    margin-top: 1rem;
    margin-bottom: 1rem;
}